import React, { useEffect, useRef, useState }  from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { isBrowser, getImagesLoadingStatus, union, intersection, areSetsEqual, delay, symmetricDifference } from 'addons/custom-blocks/src/utilities';
import { getPendingImages, setPendingImages, registerPendingImages, registerImagesLoaded} from '../../actions';

import './page-loader.less';
import { en } from '../../../omelette/src/constants/Languages';

const INCREMENT_CHECK_INTERVAL = 6;
const PageLoaderInsertion = (props) => {
    const ref = useRef();

    const [intervalId, setIntervalId] = useState(null);
    const [showClass, setShowClass] = useState("inactive");
    const [loadProgress, setLoadProgress] = useState(0);
    const [imagesLeft, setImagesLeft] = useState(-1);
    const [consecutiveNoChange, setConsecutiveNoChange] = useState(0);
    const [imageLoadStatus, setImageLoadStatus] = useState({
            urlsLeft: new Set(), 
            urlsLoaded: new Set(),
            contentRelevantImages: new Set(), 
            allLoaded: false});

    const dispatch = useDispatch();

    const url = useSelector((state) => {
      return state.router.location.pathname
    });

    const loading = useSelector((state) => {
      return state.content.get.loading
    });

    const loaded = useSelector((state) => {
      return state.content.get.loaded
    });

    const pendingImages = useSelector((state) => {
      return state.pendingImages
    });  

    const error = useSelector((state) => {
      return state.content.get.error
    });


    useEffect(() => {
      const progress = imageLoadStatus.contentRelevantImages.size != 0 ? 100*imageLoadStatus.urlsLoaded.size/imageLoadStatus.contentRelevantImages.size : 0;
      // console.log(`[imageLoadStatus] imageLoadStatus (% ${progress}/${loadProgress}) status is`, imageLoadStatus);
      setLoadProgress(progress);
    }, [imageLoadStatus]);

    useEffect(( ) => {
      if (loadProgress % INCREMENT_CHECK_INTERVAL === 0) {
        calculateContentLoadProgress();
      }
      // console.log(`PageLoaderInsertion[loadProgress] url=${url}; loadProgress: ${loadProgress}`);
      setShowClass ((loadProgress>=100) ? "inactive" : "active")
    }, [loadProgress]);

    const calculateContentLoadProgress = () => {
        const images = Array.from(document.images);
        const loadStatus = getImagesLoadingStatus(document);

        const newUrlsLeft = intersection(loadStatus.urlsLeft, imageLoadStatus.urlsLeft);
        const newUrlsLoaded = union(loadStatus.urlsLoaded, imageLoadStatus.urlsLoaded);

        // console.log(`** >> PageLoaderInsertion calculateContentLoadProgress loadStatus, newUrlsLeft, newUrlsLoaded, imageLoadStatus`, loadStatus, newUrlsLeft, newUrlsLoaded, imageLoadStatus);

        setImageLoadStatus({
            urlsLeft: loadStatus.urlsLeft,
            urlsLoaded: loadStatus.urlsLoaded,
            contentRelevantImages: loadStatus.contentRelevantImages,
            allLoaded: loadStatus.allLoaded
        });

        // console.log(`areSetsEqual(loadStatus.contentRelevantImages, pendingImages.requiredUrls) [${pendingImages.progress}]`, areSetsEqual(loadStatus.contentRelevantImages, pendingImages.requiredUrls), loadStatus.contentRelevantImages, pendingImages);
        // console.log(`areSetsEqual(newUrlsLoaded, pendingImages.loadedUrls) [${pendingImages.progress}]`, areSetsEqual(newUrlsLoaded, pendingImages.loadedUrls), newUrlsLoaded, pendingImages);
        
        (!areSetsEqual(loadStatus.contentRelevantImages, pendingImages.requiredUrls)) && dispatch(registerPendingImages(url, Array.from(loadStatus.contentRelevantImages)));
        (!areSetsEqual(newUrlsLoaded, pendingImages.loadedUrls)) && dispatch(registerImagesLoaded(url, Array.from(newUrlsLoaded)));


        // Before we update Load Progress, check if we have a new value greater than the current one.
        // This is because we have a time-based incrementer that will update the progress bar even if no new images are loaded.
        // This is to prevent the progress bar from appearing to be stuck at a certain value.
        // const newLoadProgress = Math.round((loadStatus.contentRelevantImages.size - loadStatus.urlsLeft.size)/loadStatus.contentRelevantImages.size * 100);
        // if ((newLoadProgress > loadProgress)) {
        //   setLoadProgress( newLoadProgress );
        // }
        
        // setImagesLeft(loadStatus.urlsLeft.size);
        
        // dispatch(registerImagesLoaded(url, newUrlsLoaded));
        // dispatch(setPendingImages(url, loadStatus.urlsLeft.size, new Array.from(loadStatus.urlsLeft), allLoaded));

        // console.log(`PageLoaderInsertion (calculateContentLoadProgress) uniqueUrls.size=${uniqueUrls.size}; contentRelevantImages=${contentRelevantImages.size}; urlsLoaded.length=${urlsLoaded.size}; urlsLeft=${urlsLeft.size}`);
    
        // loadStatus.urlsLeft.forEach(url => {
        //   console.log(`PageLoaderInsertion (calculateContentLoadProgress, addListeners) url=${url}`)
        //   const image = images.filter(img => img.src === url)[0];
        //   image.addEventListener('load', (event) => {
        //     console.log(`image event listener (load) url=${event.currentTarget.src}`, event.currentTarget);
        //     const loadStatus = getImagesLoadingStatus(document);

        //     dispatch(registerImagesLoaded(url, [event.currentTarget.src]));
        //     // calculateContentLoadProgress();
        //   });
        //   image.addEventListener('error', (event) => {
        //     // console.log(`image event listener (error) url=${event.currentTarget.src}`, event.currentTarget);
        //     dispatch(registerImagesLoaded(url, [event.currentTarget.src]));
        //     // calculateContentLoadProgress();
        //   });
        // });          
    }
    
    // useEffect(() => {
    //   // console.log(`PageLoaderInsertion [imagesLeft] imagesLeft=${imagesLeft} showClass: ${showClass}`);
    //   if (imagesLeft < 1) {
    //     setShowClass("inactive");
    //     // reset progress counter otherwise it starts off with 100 on the next page load
    //     setLoadProgress(0);
    //     // setImagesLeft(-1);
    //     // notify components that there are no more pending images
    //     // dispatch(setPendingImages(url, 0));
    //   } else {
    //     setShowClass("active");
    //   }
    // }, [imagesLeft]);

    // useEffect(() => {
    //   setShowClass((imageLoadStatus.allLoaded == true) ? "inactive" : "active")      
    // }, [imageLoadStatus])

    useEffect(() => {
      // console.log(`PageLoaderInsertion  [url]  url=${url} loading: ${loading} loaded: ${loaded} error: ${error} showClass: ${showClass}`);
      // Set image load status to zero images pending
      // but also set allLoaded to false so that the loader is not hidden
      // and we have control as to the real meaning of there being zero images left to load.
      setImageLoadStatus({
        urlsLeft: new Set(), 
        urlsLoaded: new Set(),
        contentRelevantImages: new Set(), 
        allLoaded: false});
      setConsecutiveNoChange(0);
      dispatch(setPendingImages(url, []));
    }, [url]);

    // useEffect(() => {
    //   console.log(`>>>>>>>>>>> ///////////// `, Array.from(document.images).map((i) => {
    //     return i.getAttribute('loading')}
    //   ));
    //   // console.log(`IMAGES: `, Array.from(document.images))
    // }, []);

    const incrementLoader = (increment) => {
      // don't increment if > 95
      // REVIEW
      // setLoadProgress((loadProgress) => (loadProgress > 95-increment) ? loadProgress : loadProgress + increment);
      setConsecutiveNoChange((consecutiveNoChange) => consecutiveNoChange + 1);
    }

    // useEffect(() => {
    //   // console.log(`PageLoaderInsertion [] url=${url} loadProgress: ${loadProgress} (initializing)`);
    //   console.log(`PageLoaderInsertion [] url=${url} imageLoadStatus: (useEffect)`, imageLoadStatus);
    //   // dispatch(setPendingImages(url, imageLoadStatus.urlsLoaded.size, Array.from(imageLoadStatus.contentRelevantImages), false));
    // }, [imageLoadStatus]);

    // useEffect(() => {
    //   console.log(`PageLoaderInsertion imageLoadStatus is -> `, imageLoadStatus);
    // }, [imageLoadStatus])

    // useEffect(() => {
    //   console.log(`PageLoaderInsertion pendingImages is -> `, pendingImages);
    // }, [pendingImages]);

    useEffect(() => {
      // console.log(`PageLoaderInsertion [] url=${url} loadProgress: ${loadProgress}`);
      if(isBrowser()) {

          const imageStatus = getImagesLoadingStatus(document);
          dispatch(setPendingImages(url, Array.from(imageStatus.contentRelevantImages)));

          const loadedUrls = Array.from(imageStatus.urlsLoaded);
          dispatch(registerImagesLoaded(url, loadedUrls));

          // console.log(`PageLoaderInsertion [] url=${url} loadProgress: ${loadProgress} (initializing)`, imageStatus);

          const interval = setInterval(() => {
            incrementLoader(1);
            calculateContentLoadProgress();

            getPendingImages(url);

            // check if loadProgress is divisible by 5 and if so, calculate the content load progress
            // if (loadProgress % 5 === 0 && loadStatus.allLoaded === false) {
            //   // const images = Array.from(document.images);
            //   // const loadStatus = getImagesLoadingStatus(document);
            //   // console.log(`PageLoaderInsertion Time Interval % 5 [] url=${url} loadProgress: ${loadProgress} (calculating content load progress)`, loadStatus);
            //   calculateContentLoadProgress();
            // }
          }, 1000);
      
          return () => {
            setLoadProgress(0);
            setConsecutiveNoChange(0);
            clearInterval(interval);
          }
      }
    }, []);

    // useEffect(() => {
    //   // console.log(`PageLoaderInsertion [] url=${url} loadProgress: ${loadProgress}`);
    //   // console.log(`PageLoaderInsertion useEffect [pendingImages] url=${url}; loadProgress: ${loadProgress}; pendingImages: `, pendingImages);
    //   if (pendingImages.progress == 1) {
    //     console.log("********** pendingImages.progress == 1");
    //     setShowClass("inactive");
    //     // reset progress counter otherwise it starts off with 100 on the next page load
    //     setLoadProgress(0);
    //   } else {
    //     setShowClass("active");
    //     if (loadProgress < pendingImages.progress*100) {
    //       setLoadProgress(Math.round(pendingImages.progress*100));
    //       setConsecutiveNoChange(0);
    //       console.log(`PageLoaderInsertion (useEffect|pendingImages) loadProgress=${loadProgress} pendingImages.progress=${pendingImages.progress}; consecutiveNoChange=${consecutiveNoChange}`);
    //       // console.log(`prior to delay`, pendingImages);
    //       // delay(1000).then(calculateContentLoadProgress());
    //     }
    //   }
    // }, [pendingImages]);

    
    // const onImageLoad = (event) => {
    //   console.log(`Picture successfully ${event.currentTarget.src} loaded.`);
    //   const newUrlsLoaded = union(loadStatus.urlsLoaded, new Set([event.currentTarget.src]));
    //   const newUrlsLeft = difference(loadStatus.urlsLeft, new Set([event.currentTarget.src]));

    //   console.log(`** >> PageLoaderInsertion onImageLoad [] url=${url} newUrlsLoaded, newUrlsLeft`, newUrlsLoaded, newUrlsLeft);
    //   setImageLoadStatus({
    //     urlsLeft: newUrlsLeft,
    //     urlsLoaded: newUrlsLoaded,
    //     contentRelevantImages: loadStatus.contentRelevantImages,
    //   });
    // }

    // useEffect(() => {
    //   if (consecutiveNoChange > 6 && pendingImages.progress < 1) {
    //     const stillPending = symmetricDifference(pendingImages.loadedUrls, pendingImages.requiredUrls);
    //     // console.log(`PageLoaderInsertion [consecutiveNoChange] url=${url} loadProgress: ${loadProgress} (initializing); consecutiveNoChange: ${consecutiveNoChange}; stillPending:`);
    //     // console.log(stillPending);

    //     if (stillPending.size === 0) {
    //       // dispatch(setPendingImages(url, []));
    //       if (loadProgress >= 95) {
    //         setLoadProgress(82);
    //       }
    //     } else {  
    //       stillPending.forEach((imgUrl) => {
    //         fetch(url).then((response) => {
    //           if (response.status === 200) {
    //             // console.log(`PageLoaderInsertion [fetching] url=${imgUrl}; response.status: ${response.status}`);
    //             dispatch(registerImagesLoaded(url, [imgUrl]));
    //           }
    //         });
    //       });
    //     } 
    //   }    
    // }, [consecutiveNoChange]);

    return(<div ref={ref} id="page-loader" className={showClass} >
              <div id="load-bar" >
                <div id="load-bar-fill" data-progress={loadProgress} style={{width:`${loadProgress}%`}}></div>
              </div>
              {/* <div  id="load-percent"><span>{loadProgress}</span></div> */}
            </div>)


    // return (<div ref={ref} id="page-loader" >
    //   <h1>HERE'S THE PAGE LOADER</h1>
    //   </div>)

}


export default PageLoaderInsertion;