import React, { useEffect, useRef, useState }  from 'react';
import {useSelector} from 'react-redux';
import SingleQuestionView from './SingleQuestionView';
import internationalQuestions from './DefaultQuestions';

import './faq.less';

const FAQView = (props) => {
    const {
        defaultQuestions=true,
        title='Frequently Asked Questions',
        theme='light',
      } = props.data;

    const componentClassName = `FAQViewContainer`

    const [activeQuestion, setActiveQuestion] = useState(-1);

    const locale = useSelector((state) => state.intl.locale);

    const intlQuestions = (locale=='en' || locale=='pt') ? internationalQuestions[locale] : internationalQuestions['en'];

    // const orderedQuestions = intlQuestions.map((q) => {
    //     return {
    //         position: q.position,
    //         title: q.title,
    //     }
    // }).toArray()

    // console.log(`>>>>>>>>>>>>> FAQ PROPS`, props);
    
    const orderedQuestions = Object.values(intlQuestions).sort((a,b) => a.position - b.position);

    const questions = orderedQuestions.map((q) => {
        return (
            <SingleQuestionView
                keyToUse={q.position}
                number={q.position+1}
                title={q.title}
                question = {q.question}
                answer = {q.answer}
                theme={theme}
                activeQuestion={activeQuestion}
                setActiveQuestion={setActiveQuestion}
            />
        )
    });

    // const questions = [1,2,3,4,5].map((n) => {
    //     return (
    //         <SingleQuestionView
    //             number={n}
    //             title={`Question ${n}`}
    //             content={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc semper, tortor id blandit mollis, libero lacus eleifend neque, 
    //                       a tincidunt ex enim ac mi. Cras tempus, lectus a ultricies rhoncus, felis sapien varius nibh, at scelerisque lacus lorem in odio. 
    //                       Aenean auctor placerat ligula, sit amet bibendum quam interdum finibus. Proin id mattis eros. Nullam molestie magna diam, ac 
    //                       laoreet nulla ullamcorper faucibus. Praesent sollicitudin nisi vitae nisl euismod, eu commodo massa vehicula. Quisque sit 
    //                       amet rhoncus dolor. Praesent bibendum volutpat nulla in scelerisque. Sed fermentum velit nibh, cursus cursus erat 
    //                       sollicitudin id. Etiam varius nisl vitae lobortis fringilla. Pellentesque habitant morbi tristique senectus et netus 
    //                       et malesuada fames ac turpis egestas. Donec quis pellentesque diam. Nulla imperdiet risus ligula, vel aliquam 
    //                       ipsum posuere sed.
    //             `}
    //             theme={theme}
    //             activeQuestion={activeQuestion}
    //             setActiveQuestion={setActiveQuestion}
    //         />
    //     )
    // });

    return (
        <div className={componentClassName}>
            <div className='faq-container'>
                <h2>{title}</h2>
                {questions}
            </div>
        </div>       
    );
}    

export default FAQView;