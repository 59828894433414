import React, { useEffect, useRef, useState }  from 'react';
import {useSelector} from 'react-redux';

// import CategoriesView from '../Categories/CategoriesView';
import ContentTagView from '../Categories/ContentTagView';
import { forceSameDomain, isDesktop } from '../utilities';
import './hero-ir.less';
import $ from 'jquery';

let platform = require('platform');

const HeroIRView = (props) => {
  let ref = useRef();

  const [heroTitle, setHeroTitle] = useState("");
  const [description, setDescription] = useState("");
  const [inlineStyles, setInlineStyles] = useState({});
  const [imageCorrectionStyles, setImageCorrectionStyles] = useState({});
  

  const contentData = useSelector((state) => state.content.data);
  const contentTitle = useSelector((state) => state.content.data.title);
  const contentDescription = useSelector((state) => state.content.data.description);

  const loaded = useSelector((state) => {
    return state.content.get.loaded
  });


  var image = "";
  if (props.data.image != undefined) {
    try {
      image = forceSameDomain(props.data.image[0]["getURL"].replace(process.env.RAZZLE_API_PATH, "") + "/@@images/image");
    } catch (e) {
      image = "";
    }
    
  }

  var horizontalOffset = (props.data.horizontal_offset != undefined) ? props.data.horizontal_offset + "%" : 0;
  var verticalOffset = (props.data.vertical_offset != undefined) ? props.data.vertical_offset + "%" : 0;

  // var secondHorizontalOffset = (props.data.horizontal_offset_new_text != undefined) ? props.data.horizontal_offset_new_text + "%" : 0;
  // var secondVerticalOffset = (props.data.vertical_offset_new_text != undefined) ? props.data.vertical_offset_new_text + "%" : 0;


  // var title = props.data.title;
  var breadcrumbsBottom = (props.data.breadcrumbs_bottom != undefined) ? props.data.breadcrumbs_bottom : false;
  const imageAlign = (props.data.image_left) ? "alignLeft" : "alignRight";
  const blockClass = `hero ${imageAlign}`;
  const title = props.data.title;
  let h1Block = ""

  // console.log(`********* HERO IR VIEW *********`, props);
  
  const defineTextBlocks = () => {
    try {
      // console.log(`>>>>>>>>>> h1Block`);
      let metas = [];
      try {
        metas = (document != undefined) ? [].slice.call(document.getElementsByTagName('meta')) : [];  
        h1Block = (document != undefined) ? document.getElementsByClassName("documentFirstHeading")[0].textContent : "";
      } catch (e) {
        metas = [];
        h1Block = "";
      }
      
      const descriptionMeta = metas.filter((e) => e.getAttribute('name') == "description")[0];
          
      setHeroTitle(h1Block);
      setDescription((descriptionMeta!==undefined) ? descriptionMeta.getAttribute("content") : '');
      
  
      // title = document.getElementsByClassName("documentFirstHeading")[0].innerHTML; 
  
    } catch (e) {
      console.log(`>>>>>>>>>> EXCEPTION h1Block`, e);
      // console.log(e);
    }
  }  

  useEffect(() => {
    defineTextBlocks();
    try {
      const bodyWidth = document.body.offsetWidth;
      const imageWidth = bodyWidth/2.0; // Hero image is half the width
      const imageProportionWidthToOriginal = imageWidth/2000.0; // We use 2kx3k images 
      const imageProportionHeight = 3000.0 * imageProportionWidthToOriginal // Height needed
      // console.log(`setInlineStyles height to ${imageProportionHeight}px`);
      if (isDesktop()) {
        setInlineStyles({ height: `${imageProportionHeight}px`, });
        if (platform.name.toLowerCase().includes("safari")) setImageCorrectionStyles({height: 'auto'})
      }
    } catch (ex) {
      console.log(`exception calculating hero height`, ex);
    }
  }, []);

  useEffect(() => {
    if (loaded) {
      defineTextBlocks();
    }
    
  }, [loaded]);





  // function setHeroOffset(){

  //   var heroBlock = document.getElementsByClassName("hero");
    

  //   for (var i = 0; i < heroBlock.length; i++) {
  //     var horizontal_offset = heroBlock[i].childNodes[0].getAttribute("horizontal-offset");
  //     var vertical_offset = heroBlock[i].childNodes[0].getAttribute("vertical-offset");

  //     var translation =  "(" + horizontal_offset + "," + vertical_offset + ")";

  //     $(heroBlock[i].childNodes[0]).css("transform", "translate"+translation);


  //   }
    
  // }

 
  if (typeof window !== 'undefined') {
    $(document).ready( function() {
      try {
        // document.getElementsByClassName("hero-title")[0].innerHTML = props.data.title;
        // setHeroOffset();
        const heroHeight = (document.getElementsByClassName("hero")[0].clientHeight - 110).toString();
        if (breadcrumbsBottom) {
          // document.getElementsByClassName("breadcrumb")[0].style.marginTop = heroHeight + "px";
          let breadcrumbs = document.getElementsByClassName("breadcrumbs")[0];
          breadcrumbs.style.top = heroHeight + "px";
          window.addEventListener('resize', (event) => {
            const newHeroHeight = (document.getElementsByClassName("hero")[0].clientHeight - 110).toString();
            breadcrumbs.style.top = newHeroHeight + "px";
            // console.log(`>>>>>>>>>>>>>> WE'RE ON RESIZE ${newHeroHeight}px`);

          });

          
        }
      } catch (e) {
        console.log("HeroIRView.jsx Execption Caught", e);
        // console.log(e);
      }
      
    } );

  // $(window).scroll(function(){

  //   var scrollTop = $(window).scrollTop();
  //   var scrollLimit = 0;

  //   if ($(window).width() > 767){
  //     scrollLimit = 150;
  //   }
  //   else {
  //     scrollLimit = 50;
  //   }

  //   if (scrollTop > scrollLimit ){
  //     console.log(`scrollTop=${scrollTop}; scrollLimit=${scrollLimit}`);
  //     title = props.data.title;

  //     var translation =  "(" + secondHorizontalOffset + "," + secondVerticalOffset + ")";
  //   } else {
  //     try {
  //       title = document.getElementsByClassName("documentFirstHeading")[0].innerHTML;
  //       const translation =  "(" + horizontalOffset + "," + verticalOffset + ")";
  //       console.log(`We'll use title ${title}`);
  //     } catch (e) {
  //       console.log("HeroIRView.jsx Execption Caught");
  //       console.log(e);
  //     }
  //   }

  //   try {
  //     document.getElementsByClassName("hero-title")[0].innerHTML = title;
  //     document.getElementsByClassName("hero-title")[0].style.transform = "translate" + translation;  
  //   } catch (e) {
  //     console.log(`FOUND EXCEPTION SETTING TITLE `);
  //     console.log(e);
  //   }
  // });

};
  
  return( 
  <div className={blockClass} ref={ref} style={inlineStyles}>
    <div className="hero-text" horizontal-offset={horizontalOffset} vertical-offset={verticalOffset}>
      <h1 className="hero" suppressHydrationWarning={true}>{contentTitle}</h1>
      <h2>{title}</h2>
      <div className="hero-description">{contentDescription}</div>
      {/* <CategoriesView {...props} /> */}
      <ContentTagView layoutStyle="xlarge" fsegments={props.properties.fsegments} fgenres={props.properties.fgenres} fstructures={props.properties.fstructures} fcompositions={props.properties.fcompositions} faddons={props.properties.faddons} />
    </div>
    <div className='hero-image-container'><img src={image} alt="hero img" loading="lazy" style={imageCorrectionStyles}/></div>
  </div>)
};

export default HeroIRView;