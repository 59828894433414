import React, { useState } from 'react';

import InlineForm from '@plone/volto/components/manage/Form/InlineForm';

// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';

import VideoPlayerView from './VideoPlayerView';
import VideoPlayerSchema from './VideoPlayerSchema';

import imageSVG from '@plone/volto/icons/image.svg';


import {
  Icon,
  SidebarPortal, 
} from '@plone/volto/components';


const VerticalDoubleImageHighlightEdit = (props) => {
  const { selected, onChangeBlock, block, data, intl, openObjectBrowser, required, resetSubmitUrl } = props;
  // const [activeFilter, setActiveFilter] = useState(0);
  const editProps = {
    ...props,
    data: {
      ...props.data,
      hidden: false,
      editEnabled: true
    }
  }
  return (
    <div>
      <SidebarPortal selected={selected}>
        <InlineForm
            icon={<Icon size="24px" name={imageSVG} />}
            schema={VideoPlayerSchema}
            title={VideoPlayerSchema.title}
            headerActions={<button onClick={() => {}}>Action</button>}
            footer={<div></div>}
            onChangeField={(id, value) => {
              onChangeBlock(block, {
                ...data,
                [id]: value,
              });
            }}
            formData={data}
          />

       </SidebarPortal> 

       <VideoPlayerView {...editProps} /> 
    </div>
  );
};

export default VerticalDoubleImageHighlightEdit;