import React, { useEffect, useRef, useState }  from 'react';
import { useSelector } from 'react-redux';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import {shouldAnimate, 
        changeInlineCss, 
        changeCssClasses, 
        textContainerReduction, 
        isBrowser, 
        bodyPercentToAbsolutePositioning, 
        isMobile,
        annotatedLog,
        isDesktop,
        forceSameDomain} from '../utilities';

import './image-fade-image.less';


const [REWIND, UNLOADED, INIT, RUNNING, PAUSED, STOPPED, OBSERVING] = [-2,-1,0,1,2,3,4];

const ImageFadeImageView = (props) => {

  const myId = useRef(`${Math.floor(Math.random() * 10000)}`);
  const myPinnedContainerLabel = `pinned-container-${myId}`;
  const myReduce65Label = `reduce65-image-${myId}`;
  const alignment = (props.data.alignment != undefined && props.data.alignment!=null) ? props.data.alignment : "left";
  const visible = (props.data.hidden==true) ? false : true;
  const editEnabled = (props.data.editEnabled==true) ? true : false;

  const loaded = useSelector((state) => {
    return state.content.get.loaded
  });

  
  const pendingImages = useSelector((state) => {
    return state.pendingImages.pending
  });

  // The following two lines check for the state of all page images, not just the ones in this component
  // This delays the animation until all images are loaded
  const pendingImagesObject = useSelector((state) => { return state.pendingImages });
  const imagesReady = () => {return (pendingImagesObject.pending==0 && pendingImagesObject.allLoaded==true)}



  const ref = useRef();
  const imgRef = useRef();


  const [orientation, setOrientation] = useState("");
  const [containerClass, setContainerClass] = useState("");
  const [overalContainerStatus, setOverallContainerStatus] = useState(UNLOADED);
  const [containerFixRunning, setContainerFixRunning] = useState(INIT);
  const [scrollerControlRunning, setScrollerControlRunning] = useState(INIT);
  const [reduceImageRunning, setReduceImageRunning] = useState(INIT);
  const [fadeImageRunning, setFadeImageRunning] = useState(INIT);
  const [textBackgroundSupportRunning, setTextBackgroundSupportRunning] = useState(INIT);
  const [bringTextInRunning, setBringTextInRunning] = useState(INIT);
  const [imageLoaded, setImageLoaded] = useState(false);
  // const [releaseFixedTextRunning, setReleaseFixedTextRunning] = useState(INIT);
  
  const [imageContainerClass, setImageContainerClass] = useState("image-container ");

  const [imageContainerInline, setImageContainerInline] = useState({});
  const [componentContainerClass, setComponentContainerClass] = useState("image-fade-image initial " + alignment);
  const [componentContainerInline, setComponentContainerInline] = useState({});

  const [textContainerClass, setTextContainerClass] = useState("text-container initial");
  const [textContainerInline, setTextContainerInline] = useState({});

  const [textBannerSupportClass, setTextBannerSupportClass] = useState('text-banner-support  hidden');
  const [textBannerSupportInline, setTextBannerSupportInline] = useState({});

  const [textClass, setTextClass] = useState("text ");
  const [textInline, setTextInline] = useState({});
  
  // const [fx_fixContainer] = useState(gsap.timeline() );
  // const [fx_fadeImage] = useState(gsap.timeline() );
  // const [fx_reduceImage] = useState(gsap.timeline() );
  // const [fx_bringTextIn] = useState(gsap.timeline() );
  // const [fx_releaseFixedText] = useState(gsap.timeline() );
  // const [fx_textBackgroundSupport] = useState(gsap.timeline() );

  const pinScrollerId = useRef(`pinScroller-${myPinnedContainerLabel}`);
  const fadeScrollerId = useRef(`fadeScroller-${myPinnedContainerLabel}`);
  const reduce65ScrollerId = useRef(`reduce65Scroller-${myReduce65Label}`);
  const bringTextInScrollerId = useRef(`bringTextInScroller-${myReduce65Label}`);
  const textBackgroundSupportScrollerId = useRef(`bringTexBackgroundScroller-${myReduce65Label}`);
  const releaseStickyTextScrollerId = useRef(`releaseStickyTextScroller-${myReduce65Label}`);
  const initialScrollPosition = useRef(0);

  const triggerImageHeightCalculations = (caller) => {
    if (isBrowser() && !imageLoaded && imgRef.current && imgRef.current.complete) {
      // console.log(`triggerImageHeightCalculations called from ${caller}; imageLoaded=${imageLoaded}; imgRef is completed: ${imgRef.current!=undefined && imgRef.current!=null && imgRef.current.complete}`);
      // console.log(`triggerImageHeightCalculations setting imageLoaded=true `);
      setImageLoaded(true);
    } 
  }

  const getImageUrl = (imageProp) => {
    try {
      let imgUrl = forceSameDomain(imageProp[0]["getURL"].replace(process.env.RAZZLE_API_PATH, "") + "/@@images/image");
      return imgUrl;
    } catch (e) {
      return "";
    }
  }

  useEffect(() => {
    let cssOptions = {};

    // annotatedLog(`*** overalContainerStatus=${overalContainerStatus}`, `visible=${visible}`);

    if (imagesReady() && loaded && isBrowser() && overalContainerStatus==INIT && visible && !editEnabled) {
      

      // annotatedLog("*+*+*+*+*+*+*+*+ IFI useLayoutEffect", "starts gsap");

      const revealParams = (isMobile()) ? 
                  {
                    top: "65vh"
                  } :
                  {
                    right: '0'
                  };

      if (isMobile()) {
        cssOptions = {
          right: "unset",
          left: "unset",
          top: "unset",
          bottom: "0%"
        }
      } else {
        cssOptions = (alignment=='right') ? {right: '65%'} : {left: '65%'};
      }
      const ctx = gsap.context(() => {
  
        gsap.timeline()
          .addLabel("fixContainer")
          .to(".image-fade-image-scroller", {
            scrollTrigger: {
              markers: false,
              id: pinScrollerId.current,
              trigger: '.image-fade-image-scroller span',
              start: `top top`,
              end: (self) => `+=1700`,
              onEnter: (self) => {
                setContainerFixRunning(RUNNING);
                setScrollerControlRunning(RUNNING);
              },
        
              onLeave: (self) => {
                setContainerFixRunning(STOPPED);
              },

              onLeaveBack: (self) => {
                setContainerFixRunning(INIT);
                // setScrollerControlRunning(OBSERVING);
              }
          }
          })

          .addLabel("fadeImage")
          .to("#first-image", {
            opacity: 0,
            scrollTrigger: {
              // markers: true,
              id: fadeScrollerId.current,
              trigger: ".image-fade-image-scroller span",
              start: (self) => `top -250px`, 
              end: (self) => `+=1000`,
              onEnter: (self) => {
                setFadeImageRunning(RUNNING);
              },
              onLeave: (self) => {
                setFadeImageRunning(STOPPED);
              },

              onLeaveBack: (self) => {
                setFadeImageRunning(INIT);
              },

              onEnterBack: (self) => {
                setFadeImageRunning(REWIND);
              }
              
            }    
          })

          .addLabel("textBackgroundSupport")
          .to(".text-banner-support", {
            ...revealParams,
            scrollTrigger: {
              markers: false,
              id: textBackgroundSupportScrollerId.current,
              trigger: "#first-image",
              start: (self) => `top -1320px`, 
              end: (self) => `+=280`,
              onEnter: (self) => {
                setTextBackgroundSupportRunning(RUNNING);
              },
              onLeave: (self) => {
                setTextBackgroundSupportRunning(STOPPED);
              }
            }    
          })

          .addLabel("bringTextIn")
          .to(`.image-fade-image .text-container .text` , {
          ...cssOptions,
          scrollTrigger: {
            id: bringTextInScrollerId.current,
            markers: false,
            trigger: ".image-fade-image-scroller span",
            start: (self) => `top -1380px`, 
            end: (self) => `+=220`,
            onEnter: (self) => {
              setBringTextInRunning(RUNNING);
            },
            onLeave: (self) => {
              setBringTextInRunning(STOPPED);
            },
          }    
      });
  
      return () => {
        console.log(`Image Fade Image Cleanup Called`);
      };
    
      }, ref); // <-
    }
    
  }, [overalContainerStatus]);  
  // containerFixRunning
  // useEffect(() => {
  //   if (visible && !editEnabled) {
  //     fx_fixContainer
  //     .addLabel("fixContainer")
  //     .to(".image-fade-image-scroller", {
  //       scrollTrigger: {
  //         markers: false,
  //         id: pinScrollerId.current,
  //         trigger: '.image-fade-image-scroller span',
  //         start: `top top`,
  //         end: (self) => `+=900`,
  //         onEnter: (self) => {
  //           console.log(`fixed container started`);
  //           setContainerFixRunning(RUNNING);
  //         },
    
  //         onLeave: (self) => {
  //           console.log(`fixed container finished`);
  //           setContainerFixRunning(STOPPED);
  //         },
  //     }
  //     });   
  //   }
    
  // }, []);

  useEffect(() => {

    if (scrollerControlRunning==OBSERVING && bringTextInRunning==STOPPED) {
        annotatedLog("scrollerControlRunning", "PAUSED/STOPPED", scrollControllerId.current);
        const scroller = ScrollTrigger.getById(scrollControllerId.current);

        const containerBlock = scroller.trigger.parentNode.parentNode.parentNode;
        const textContainerBlock = containerBlock.children[0].children[3];
        const isFinal = textContainerBlock.classList.contains("final");
        // console.log("scrollerControlRunning containerBlock . Is final: " + isFinal);
        // console.log(containerBlock);
        if (!isFinal) {
          changeCssClasses( textContainerClass, setTextContainerClass, ["flowing"], ["sticky"]); 
        }
        
      
    }
    
  }, [scrollerControlRunning]);

  const handleLoad = () => {
    // console.log("+++++++++++++++++++ ======================= handleLoad CALLED");
    if (loaded && imagesReady()) {
      if(imgRef.current && imgRef.current.complete) {
        triggerImageHeightCalculations("useEffect");
        setOverallContainerStatus(INIT);
      } else {
        // console.log(`useEffect called but imgRef not ready. triggerImageHeightCalculations will be called by onLoad callback. imgRef.current is`);
        // console.log(imgRef.current);
      } 
    }
  }

  useEffect(() => {
    handleLoad();
  }, [loaded]);

  // useEffect(() => {
  //   handleLoad();
  // }, [pendingImages]);

  useEffect(() => {
    handleLoad();
  }, [pendingImagesObject]);

  useEffect(() => {
    // console.log("+++++++++++++++++++ ======================= useEffect[] CALLED");

    

    window.addEventListener('load', handleLoad);

    // this is the unmout function
    return () => {
      window.removeEventListener('load', handleLoad);
    }

  }, []);


  useEffect(() => {
    try {
      if (overalContainerStatus==INIT && containerFixRunning==INIT) {
        // annotatedLog(`>>>>>>>>>> IFI containerFixRunning=${containerFixRunning}`, "INIT");
  
        const scroller = ScrollTrigger.getById(pinScrollerId.current);    

        // annotatedLog(`IFI containerFixRunning`, `scroller is ${pinScrollerId.current}`);
        // console.log(scroller);

        const containerBlock = scroller.trigger.parentNode.parentNode.parentNode;
        const previousSibling = containerBlock.previousSibling;
  
        changeCssClasses( componentContainerClass, setComponentContainerClass, ["initial"], ["fixed"]);
  
        if (previousSibling!= null && previousSibling!= undefined) {
          previousSibling.style.marginBottom = `0`;
        }
  
      }
  
      if (containerFixRunning==RUNNING) {
        const body = document.getElementsByTagName("body")[0];
        const bodyWidth = body.offsetWidth;
        const viewportWidth = window.innerWidth;
        const leftMargin = (viewportWidth-bodyWidth)/2;
        const scroller = ScrollTrigger.getById(pinScrollerId.current);    
        const containerBlock = scroller.trigger.parentNode.parentNode.parentNode;
        const previousSibling = containerBlock.previousSibling;
            
        initialScrollPosition.current = window.pageYOffset;
  
        // console.log(`setting initialScrollPosition to ${initialScrollPosition.current}`);
        // annotatedLog("IFI containerFixRunning", "RUNNING");
  
        changeCssClasses( componentContainerClass, setComponentContainerClass, ["fixed"], ["initial"]);
  
        changeInlineCss(componentContainerInline, 
                        setComponentContainerInline, 
                        {
                          left: `${leftMargin}px`,
                          width: `${bodyWidth}px`
                        }, 
                        []
                        );
  
        if (previousSibling!= null && previousSibling!= undefined) {
          previousSibling.style.marginBottom = `300vh`;
        }
  
      }
  
      if (containerFixRunning==PAUSED || containerFixRunning==STOPPED ) {
        annotatedLog("IFI containerFixRunning", "STOPPED");
        const scroller = ScrollTrigger.getById(pinScrollerId.current);
        if (scroller!=undefined && scroller!=null) {
          const containerBlock = scroller.trigger.parentNode.parentNode.parentNode;
          const previousSibling = containerBlock.previousSibling;
  
          changeCssClasses( componentContainerClass, setComponentContainerClass, ["reduce65", "loose", "final"], ["fixed", "text-fly-in"]); 
          changeInlineCss(componentContainerInline, setComponentContainerInline, {}, ["width", "left"]);
  
          if (isDesktop()) {
            // This fix is required for large screens > 2k px wide
            changeInlineCss(textContainerInline, setTextContainerInline, {
              left: "65%"
            }, ["width"]);
          }
          
  
          const deviceBasedWidth = (isMobile()) ? "90%" : "35%";
          changeInlineCss(textBannerSupportInline, setTextBannerSupportInline, {
            width: deviceBasedWidth
          }, []);  
          
                  
          previousSibling.style.marginBottom = `0`;
  
          // console.log(`jumping to initialScrollPosition of ${initialScrollPosition.current}`);
  
          window.scrollTo(0, initialScrollPosition.current);
          scroller.kill();
          // triggerScrollTriggerRefresh([releaseStickyTextScrollerId.current]);
          ScrollTrigger.refresh();
        }
        // fx_fixContainer.kill();
      } 
    } catch (e) {
      console.log(`Error in IFI containerFixRunning useEffect. containerFixRunning=${containerFixRunning}`, e)
      // console.log(e);
    }


    
  }, [containerFixRunning]);

  // fadeImageRunning
  // useEffect(() => {
  //   console.log(">> Setting Up fadeImageFX");
  //   fx_fadeImage
  //       .addLabel("fadeImage")
  //       .to("#first-image", {
  //         opacity: 0,
  //         scrollTrigger: {
  //           // markers: true,
  //           id: fadeScrollerId.current,
  //           trigger: ".image-fade-image-scroller span",
  //           start: (self) => `top -50px`, 
  //           end: (self) => `+=400`,
  //           onEnter: (self) => {
  //             console.log("   fadeImageFX ON_ENTER");
  //             setFadeImageRunning(RUNNING);
  //           },
  //           onLeave: (self) => {
  //             console.log("   fadeImageFX ON_EXIT");
  //             setFadeImageRunning(STOPPED);
  //           }
  //         }    
  //       });  
  //   }, []);
  
  useEffect(() => {

    // if (fadeImageRunning==INIT) {
    //   annotatedLog("IFI fadeImageRunning", "INIT");
    // }

    if (fadeImageRunning==REWIND) {
      // annotatedLog("IFI fadeImageRunning", "REWIND");
      changeCssClasses( componentContainerClass, setComponentContainerClass, [], ["fade-complete"]);
    }

    if (fadeImageRunning==PAUSED ||fadeImageRunning==STOPPED ) {
      // annotatedLog("IFI fadeImageRunning", "STOPPED");
      changeCssClasses( componentContainerClass, setComponentContainerClass, ["fade-complete"], []);

      const scroller = ScrollTrigger.getById(fadeScrollerId.current);
      if (scroller!=undefined && scroller!=null) {
        // scroller.kill();
      }
      // fx_fadeImage.revert();
      // fx_fadeImage.kill();  
    }
  }, [fadeImageRunning]);

  // textBackgroundSupport
  // useEffect(() => {
  //   console.log(">> Setting Up textBackgroundSupport");
  //   fx_textBackgroundSupport
  //       .addLabel("textBackgroundSupport")
  //       .to(".text-banner-support", {
  //         right: '0',
  //         scrollTrigger: {
  //           markers: false,
  //           id: textBackgroundSupportScrollerId.current,
  //           trigger: "#first-image",
  //           start: (self) => `top -520px`, 
  //           end: (self) => `+=280`,
  //           onEnter: (self) => {
  //             console.log("   textBackgroundSupport ON_ENTER");
  //             setTextBackgroundSupportRunning(RUNNING);
  //           },
  //           onLeave: (self) => {
  //             console.log("   textBackgroundSupport ON_EXIT");
  //             setTextBackgroundSupportRunning(STOPPED);
  //           }
  //         }    
  //       });  
  //   }, []);

  useEffect(() => {
    if (textBackgroundSupportRunning == RUNNING) {
      // annotatedLog("IFI textBackgroundSupportRunning", "RUNNING");
      const deviceBasedWidth = (isMobile()) ? 90 : 35;

      changeInlineCss(textBannerSupportInline, setTextBannerSupportInline, { 
        width: `${bodyPercentToAbsolutePositioning(deviceBasedWidth)}px`
      }, [] );

    }

    if (textBackgroundSupportRunning==PAUSED ||textBackgroundSupportRunning==STOPPED ) {
      // annotatedLog("IFI textBackgroundSupportRunning", "STOPPED");
      const inlinCssOptions = (isMobile()) ?
            {
              top: "65vh"
            } :
            {
              right: 0,
              // leave top untouched
            }
      const scroller = ScrollTrigger.getById(textBackgroundSupportScrollerId.current);
      if (scroller!=undefined && scroller!=null) {
        changeCssClasses( textBannerSupportClass, setTextBannerSupportClass, ["final"], ["hidden"]);
        changeInlineCss(textBannerSupportInline, setTextBannerSupportInline, { 
          ...inlinCssOptions
          // right: 0//`${bodyPercentToAbsolutePositioning(35)}px`
        }, [] );
        scroller.kill();
      }
      // fx_textBackgroundSupport.revert();
      // fx_textBackgroundSupport.kill();

      // changeCssClasses(componentContainerClass, setComponentContainerClass, ["reduce65"], ["initial"]);
      // changeInlineCss(imageContainerInline, setImageContainerInline, {}, ['width', 'min-width']);
    }
  }, [textBackgroundSupportRunning]);

  // useEffect(() => {
  //   const cssOptions = (alignment=='right') ? {right: '65%'} : {left: '65%'};
  //   fx_bringTextIn
  //     .addLabel("bringTextIn")
  //     .to(`.image-fade-image .text-container .text` , {
  //       ...cssOptions,
  //       scrollTrigger: {
  //         id: bringTextInScrollerId.current,
  //         markers: false,
  //         trigger: ".image-fade-image-scroller span",
  //         start: (self) => `top -580px`, 
  //         end: (self) => `+=220`,
  //         onEnter: (self) => {
  //           setBringTextInRunning(RUNNING);
  //         },
  //         onLeave: (self) => {
  //           setBringTextInRunning(STOPPED);
  //         },
  //       }    
  //     }) 
  // }, []);

  useEffect(() => {
    if (bringTextInRunning==RUNNING) {
      // annotatedLog("IFI bringTextInRunning", "RUNNING");
      const scroller = ScrollTrigger.getById(bringTextInScrollerId.current);
      if (scroller!=undefined && scroller!=null) {
        const body = document.getElementsByTagName("body")[0];
        const bodyWidth = body.offsetWidth;
        const viewportHeight = window.innerHeight;
        
        const containerBlock = scroller.trigger.parentNode.parentNode.parentNode;
        const textContainer = containerBlock.querySelector(".text-container");
        const text = textContainer.querySelector(".text");
        const textWidth = (isMobile()) ? bodyWidth-50 : 0.35*bodyWidth - 70;
        const textHeight = text.clientHeight + 2*50; // Add some margin
        const textReductionFactor = (textHeight>viewportHeight) ? (1.0*textHeight)/viewportHeight : 1;
        // const textPos = viewportHeight-text.clientHeight-(2.5*54);
        const textPos = Math.max(0, (viewportHeight-(1.0*text.clientHeight/textReductionFactor))/2 );

        // To play safe, we want text to only use 90% of viewportHeight;
        // This means we correct the top placement when calling changeInlineCss below
        const inlineCssCorrections = textContainerReduction(bringTextInScrollerId.current, text.textContent, textWidth, 0.8*viewportHeight, "'Montserrat', sans-serif", 48, 54, 0);

        // console.log(`IFI Debugging. viewportHeight=${viewportHeight}; text.clientHeight=${text.clientHeight}; textHeight=${textHeight}; textReductionFactor=${textReductionFactor}; textPos=${textPos}`);
        // console.log(inlineCssCorrections);
        changeCssClasses( componentContainerClass, setComponentContainerClass, ["text-fly-in"], []); 
        changeCssClasses( textContainerClass, setTextContainerClass, ["animated"], ["initial"]); 

        changeInlineCss(textContainerInline, setTextContainerInline, {
          width: `${textWidth}px`,
          // height: "100vh",
          // minHeight: "100vh"
        }, []);

        changeInlineCss(textInline, setTextInline, {
          ...inlineCssCorrections,
          width: `${textWidth}px`,
        }, []);

      }
    }

    if (bringTextInRunning==PAUSED ||bringTextInRunning==STOPPED ) {
      // annotatedLog("IFI bringTextInRunning", "STOPPED");
      
      const scroller = ScrollTrigger.getById(bringTextInScrollerId.current);
      const containerBlock = scroller.trigger.parentNode.parentNode.parentNode;
      const textContainer = containerBlock.querySelector(".text-container");
      const text = textContainer.querySelector(".text");


      // annotatedLog(`============= textContainer.height: ${textContainer.clientHeight}`, `text.height: ${text.clientHeight}`);
      // console.log(textContainer);
      // console.log(text);


      if (scroller!=undefined && scroller!=null) {
        let hposTextContainer = {};
        let hposText = {};
        let containerBlockProps = {}

        scroller.kill();
        if (isMobile()) {
          const viewportHeight = window.innerHeight;
          const minimumTextHeight = 0.35*window.innerHeight;
          const insideDivHeight = text.children[1].clientHeight;
          const insideDivLineHeight = parseFloat(window.getComputedStyle(text.children[1], null) .getPropertyValue("line-height").replace(/[a-zA-Z]+/g, "")); 
          const adequateHeight = Math.max(minimumTextHeight, insideDivHeight + 4*insideDivLineHeight)
          const newContainerHeight = Math.max(viewportHeight, viewportHeight - minimumTextHeight + adequateHeight);

          // annotatedLog(`........ newContainerHeight=${newContainerHeight}= viewportHeight:${viewportHeight} - minimumTextHeight:${minimumTextHeight} + adequateHeight:${adequateHeight}; insideDivHeight=${insideDivHeight}; insideDivLineHeight=${insideDivLineHeight}; `, "", "");

          containerBlockProps =  {
            height: `${newContainerHeight}px`
          }

          hposTextContainer["top"] = "calc(65vh + 1em)";
          hposTextContainer["left"] = "0px !important";
          hposTextContainer["width"] = "90% !important";
          hposTextContainer["height"] = `${adequateHeight}`;
          hposTextContainer["display"] = "flex";

          hposText["top"] = "0px";
          hposText["width"] = "100%";
          hposText["inset"] = "0.5em 0.5em 0%";

          changeInlineCss(componentContainerInline, setComponentContainerInline, containerBlockProps, ["overflow"]);
          changeInlineCss(textBannerSupportInline, setTextBannerSupportInline, {
            height: `${adequateHeight}px`
          }, [])
          
        } else {
          if (containerBlock.classList.contains("left")) {
            hposTextContainer["left"] = `${bodyPercentToAbsolutePositioning(65)}px`;
            hposText["left"] = 0;
          } else if (containerBlock.classList.contains("right")) {
            hposTextContainer["right"] = `${bodyPercentToAbsolutePositioning(65)}px`;
            hposText["right"] = 0;
          }
        }
        

        changeCssClasses( textContainerClass, setTextContainerClass, ["sticky"], ["animated"]); 
        changeCssClasses( textContainerClass, setTextContainerClass, ["sticky"], ["animated"]); 
        changeInlineCss( textContainerInline, setTextContainerInline, {
          ...hposTextContainer
        }, []);
        changeInlineCss(textInline, setTextInline, {
          ...hposText
        }, []);

        // setContainerFixRunning(STOPPED);

      }
      // fx_bringTextIn.kill();
    }
    
  }, [bringTextInRunning]);

  let text = props.data.text;
  const boldWords = (props.data.bold_words != undefined) ? props.data.bold_words : "";  
  var boldWordsArray = boldWords.split(",");
  for (var i = 0; i < boldWordsArray.length; i++) {
    var boldWord = boldWordsArray[i];
    var regex = new RegExp(boldWord, 'g');
    try {
      text = text.replace(regex, '<span style="font-weight: bold">' + boldWord + '</span>');
    } catch (e) {
      text="";
    }
    
  }

  const [image1, image2] = [getImageUrl(props.data.image), getImageUrl(props.data.image2)];

  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.defaults({
    // markers: true,
    scrub: true,             
    // toggleActions: "play pause reverse pause",
    toggleActions: "play none none none",
    // play, pause, resume, reverse, restart, reset, complete, none
    // 4 values set to pastStartPoint pastEndPoint reEnter scrollBackPastTheStart
  })

  


  const setOrientationClass = ({ target: img }) => {
    const { offsetHeight, offsetWidth } = img;
    const orientationDetected = (offsetHeight > offsetWidth) ? "portrait" : "landscape";
    setOrientation(orientationDetected);
    setContainerClass("image-container " + orientationDetected);
    
  }

  if (!visible && !editEnabled) {
    return (<div id={myPinnedContainerLabel.current} className={componentContainerClass}>visibility flag is set to {visible}</div>)
  } else {
    return( <div id={myPinnedContainerLabel.current} className={componentContainerClass} ref={ref} style={componentContainerInline}>
        <div className="image-fade-image-content-container" >
          <div className='image-fade-image-scroller'><span>&nbsp;</span></div>
          <div className="image-container-wrapper">
            <div id="first-image" className={imageContainerClass} style={imageContainerInline}>
              <img ref={imgRef} className={orientation} loading="lazy" onLoad={triggerImageHeightCalculations("onLoad")} src={image1} alt=""></img>
            </div>
            <div id="second-image" className={imageContainerClass} >
              <img className={orientation} loading="lazy" onLoad={setOrientationClass} src={image2} alt=""></img>
            </div>
          </div>
          
          <div className={textBannerSupportClass} style={textBannerSupportInline}/>
          <div className={textContainerClass} style={textContainerInline}>
            <div className={textClass} style={textInline}>
                <span></span>
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          </div>
        </div>

      </div>)
  }  
          
};

export default ImageFadeImageView;

























// import React, { useEffect, useRef, useState }  from 'react';
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

// import './image-fade-image.less';
// // import { Controller, Scene } from 'react-scrollmagic';
// // import { Tween, Timeline } from "react-gsap";


// const ImageFadeImageView = (props) => {
//   const ref = useRef();
//   const ref1 = useRef();
//   const ref2 = useRef();
//   const timeline = useRef();

//   const visible = (props.data.hidden==true) ? false : true;
//   const editEnabled = (props.data.editEnabled==true) ? true : false;

//   const [orientation, setOrientation] = useState("");
//   const [containerClass, setContainerClass] = useState("");

//   gsap.registerPlugin(ScrollTrigger);
//   ScrollTrigger.defaults({
//     scrub: true,             
//     toggleActions: "play pause reverse pause",
//     // play, pause, resume, reverse, restart, reset, complete, none
//     // 4 values set to pastStartPoint pastEndPoint reEnter scrollBackPastTheStart

//   })

//   useEffect(() => {
//     const body = document.getElementsByTagName("body")[0];
//     const bodyWidth = body.offsetWidth;
//     const viewportWidth = window.innerWidth;
//     const viewportHeight = window.innerHeight;
//     const viewportExcess = viewportWidth - bodyWidth;
//     const viewportExcessPercent = (viewportExcess/bodyWidth) * 100;
//     const largeScreenWidth = (0.65*viewportWidth/bodyWidth);
//     const widthToUse = 0.65*largeScreenWidth;
//     const leftMargin = (viewportWidth-bodyWidth)/2;
//     let textYPositionOnEnter = 0, textHeight = 0;
//     let positionOnEnter = 0;

//     const fixContainerAndHideText = (gsapTimeline, markers=false, start='top', duration=150, cssOptions={}) => {
//       var tl = gsap.timeline();
//       return tl 
//                 .addLabel("fixContainerAndHideTextStart")
//                 .to(".image-fade-image .first-image img", {
//                   // scale: `'${scaleFactor}'`,
//                   ...cssOptions,
//                   repeat:1,
//                   scrollTrigger: {
//                     markers: markers,
//                     // id: `image-fade-image`,
//                     // trigger: ".image-fade-image-content-container",
//                     trigger: ".first-image",
//                     start: (self) => `top ${start}`, 
//                     end: (self) => `+=${duration}`,
//                     onEnter: (self) => {
//                       const containerBlock = self.trigger.parentNode.parentNode.parentNode;
//                       const text = containerBlock.querySelector(".ifi-text");
//                       // const imageContainers = containerBlock.querySelectorAll(".image-container");

//                       // componentHeight = containerBlock.clientHeight;
//                       // initialScrollPosition = window.pageYOffset + containerBlock.getBoundingClientRect().top;

//                       containerBlock.classList.add("fixed");
//                       containerBlock.style.left = `${leftMargin}px`;
//                       containerBlock.style.width = `${bodyWidth}px`;
//                       text.style.width = `${0.35*bodyWidth}px`;


//                       // imageContainers.forEach((elem) => {
//                       //   elem.style.left = leftMargin;
//                       //   elem.style.width = bodyWidth;
//                       // });
                      

//                       text.classList.add("hidden");
//                       positionOnEnter = window.pageYOffset ;

//                       // console.log(`ON ENTER fixContainer -> leftMargin=${leftMargin}; bodyWidth=${bodyWidth}; window.pageYOffset=${window.pageYOffset}`);

//                       // console.log(`>>>> viewportHeight=${viewportHeight}; componentHeight=${componentHeight}; initialScrollPosition=${initialScrollPosition}`)
//                     },
//                     onLeaveBack: (self) => {
//                       const containerBlock = self.trigger.parentNode.parentNode.parentNode;
//                       containerBlock.classList.remove("fixed");
//                     }
//                   }    
//               })
//               .addLabel("fixContainerAndHideTextEnd")
//     }

//     const fadeOutImage1 = (gsapTimeline, markers=false, start=-300, duration=300, cssOptions={}) => {
//       // return gsapTimeline 
//       var tl = gsap.timeline();
//       return tl 
//                 .addLabel("fadeOutImage1Start")
//                 .to(".image-fade-image .first-image img", {
//                   ...cssOptions,
//                   scrollTrigger: {
//                     markers: markers,
//                     trigger: ".first-image",
//                     start: (self) => `top ${start}`, 
//                     end: (self) => `+=${duration}`,
                    
//                   }    
//                 })

//               .addLabel("fadeOutImage1End")
//     }

//     const reduceImageSize = (gsapTimeline, markers=false, cssOptions={}) => {
//       // return gsapTimeline 
//       var tl = gsap.timeline();
//       return tl 
//                 .addLabel("reduceImageSizeStart")
//                 .to(".image-fade-image .second-image img", {
//                   ...cssOptions,
                  
//                   scrollTrigger: {
//                     markers: markers,
//                     trigger: ".first-image",
//                     start: (self) => 'top -600', 
//                     end: (self) => `+=300`,
                    
//                   }    
//                 })


//               .addLabel("reduceImageSizeEnd")
//     }

//     const outTransitionReduce0 = (gsapTimeline, markers=false, cssOptions={}) => {
//       // return gsapTimeline 
//       var tl = gsap.timeline();
//       return tl 
//                 .addLabel("outTransitionReduce0Start")
//                 .to(".image-fade-image .image-container-wrapper", {
//                   ...cssOptions,
                  
//                   scrollTrigger: {
//                     markers: markers,
//                     trigger: ".first-image",
//                     start: (self) => 'top -900', 
//                     end: (self) => `+=600`,
                    
  
//                     onLeave: (self) => {
//                       const containerBlock = self.trigger.parentNode.parentNode.parentNode;
//                       containerBlock.classList.remove("fixed");
//                       containerBlock.style.left = `0px`;
//                       // containerBlock.classList.add("reduced");
//                     }
                    
//                   }    
//                 })

//                 .to(".image-fade-image .ifi-text", {
//                   opacity: 0,
//                   scrollTrigger: {
//                     markers: false,
//                     trigger: ".first-image",
//                     start: (self) => 'top -1200', 
//                     end: (self) => `+=300`,
//                     onLeave: (self) => {
//                       const containerBlock = self.trigger.parentNode.parentNode.parentNode;
//                       const componentHeight = containerBlock.clientHeight;
//                       const initialScrollPosition = window.pageYOffset + containerBlock.getBoundingClientRect().top;
//                       window.scrollTo({
//                         top: initialScrollPosition+componentHeight+400 // viewportHeight
//                       });
//                     }
//                   }    
//                 })


//               .addLabel("outTransitionReduce0End")
//     }



//     const outTransitionReduce65StaticImage = (gsapTimeline, markers=false, start=-900, duration=300, cssOptions={}, onLeaveHandler) => {
//       // return gsapTimeline 
//       var tl = gsap.timeline({
//         // onComplete: () => {
//         //   alert("Hello");
//         // }
//       });
//       return tl 
//                 .addLabel("outTransitionReduce65Start")
//                 .to(".text-banner-support", {
//                   ...cssOptions,
                  
//                   scrollTrigger: {
//                     markers: markers,
//                     trigger: ".first-image",
//                     start: (self) => `top ${start}`, 
//                     end: (self) => `+=${duration}`,
                    
//                     onEnter: (self) => {
//                       const containerBlock = self.trigger.parentNode.parentNode;
//                       const outerBlock = containerBlock.parentNode;
//                       const text = containerBlock.querySelector(".ifi-text");
//                       // const outerBlock = containerBlock.parentNode;
//                       const rightMargin = (viewportWidth-bodyWidth)/2;
//                       const textBannerSupportWidth = 0.35*bodyWidth+rightMargin;
//                       const textBannerSupportContainer = containerBlock.querySelector(".text-banner-support");

//                       textBannerSupportContainer.style.width = `${textBannerSupportWidth}px`;
//                       textYPositionOnEnter = text.offsetTop;
//                       textHeight = text.clientHeight;
//                       // console.log(`ON ENTER  outTransitionReduce65StaticImage -> start=${start}; textBannerSupportWidth=${textBannerSupportWidth}; textYPositionOnEnter=${textYPositionOnEnter}; viewportHeight=${viewportHeight};`);
//                     },
  
//                     onLeave: (self) => {
//                       const containerBlock = self.trigger.parentNode.parentNode;
//                       const outerBlock = containerBlock.parentNode;
//                       const outerBlockPosition = window.pageYOffset + outerBlock.getBoundingClientRect().top;
//                       const text = outerBlock.querySelector(".ifi-text-static");

//                       // const yPosition = outerBlockPosition ;
//                       const yPosition = positionOnEnter + 0*viewportHeight;
//                       const textMarginBottom = viewportHeight-textYPositionOnEnter-textHeight;

//                       // console.log(`ON LEAVE  outTransitionReduce65 -> positionOnEnter=${positionOnEnter}; viewportHeight=${viewportHeight} outerBlock.getBoundingClientRect().top=${outerBlock.getBoundingClientRect().top}; window.pageYOffset=${window.pageYOffset}`);


//                       // avoid looping due to window.scrollTo instruction
//                       if (!outerBlock.classList.contains("reduce65")) {
//                         // Let's start by removing the timeline animations, 
//                         // as we do NOT want to run them again. 
//                         onLeaveHandler();

                        
//                         text.style.marginBottom = `${textMarginBottom}px`;
//                         outerBlock.classList.remove("animations-enabled");
//                         outerBlock.classList.remove("fixed");
//                         outerBlock.classList.add("reduce65");
//                         outerBlock.style.left = `0px`;
  
//                         // outerBlock.classList.add("animations-disabled");
//                         containerBlock.classList.add("animations-disabled");
                        
//                         window.scrollTo(0, yPosition);

//                       }
                     

                      
//                     },

//                     onLeaveBack: (self) => {
//                       const containerBlock = self.trigger.parentNode.parentNode;
//                       const outerBlock = containerBlock.parentNode;
//                       const currentScroll = window.pageYOffset;
//                       const outerBlockPosition = window.pageYOffset + outerBlock.getBoundingClientRect().top;
//                       const yPosition = outerBlockPosition + viewportHeight;

//                       outerBlock.classList.remove("animations-enabled");
//                       outerBlock.classList.remove("fixed");

                      
//                       // console.log(`ON EnterBack outTransitionReduce65. outerBlock is`);
//                       // console.log(outerBlock);
//                       // console.log(`currentScroll=${currentScroll}; outerBlockPosition=${outerBlockPosition}; yPosition=${yPosition}; outerBlock.scrollTop=${outerBlock.scrollTop}; viewportHeight=${viewportHeight}`)

//                     }
                    
//                   }    
//                 })

//               .addLabel("outTransitionReduce65End")
//     }

//     const fadeTextOut = (gsapTimeline, markers=false, start=-900, duration=300, cssOptions={}) => {
//       // return gsapTimeline 
//       var tl = gsap.timeline();
//       return tl 
//                 .addLabel("fadeTextOutStart")

//                 .to(".image-fade-image .ifi-text", {
//                   opacity: 0,
                  
//                   scrollTrigger: {
//                     markers: markers,
//                     trigger: ".first-image",
//                     start: (self) => `top ${start}`, 
//                     end: (self) => `+=${duration}`,
//                     onLeave: (self) => {
//                       const containerBlock = self.trigger.parentNode.parentNode.parentNode;
//                       const componentHeight = containerBlock.clientHeight;
//                       const initialScrollPosition = window.pageYOffset + containerBlock.getBoundingClientRect().top;
//                       containerBlock.classList.remove("fixed");

//                       window.scrollTo({
//                         top: initialScrollPosition+componentHeight+400 // viewportHeight
//                       });
//                     }
//                   }    
//                 })


//               .addLabel("fadeTextOutEnd")
//     }

//     const bringTextIn = (gsapTimeline, markers=false, start=0, duration=100, cssOptions={}) => {
//       // return gsapTimeline 
//       var tl = gsap.timeline();
//       return tl 
//                 .addLabel("bringTextInStart")
//                 .from(".image-fade-image .ifi-text", {
//                   ...cssOptions,
                  
//                   scrollTrigger: {
//                     markers: markers,
//                     trigger: ".first-image",
//                     start: (self) => `top ${start}`, 
//                     end: (self) => `+=${duration}`,
//                     onEnter: (self) => {
//                       const textContainer = self.trigger.parentNode.nextElementSibling;
//                       textContainer.classList.remove("hidden");
//                       // console.log(`ON ENTER bringTextIn -> start=${start}`)
//                     },
//                   }    
//                 })


//               .addLabel("bringTextInEnd")
//     }



//     if (visible && !editEnabled) { // we hide animations on edit
//       let ctx;

//       const onLeaveHandler = () => {
//         ctx.revert();
//       }

//       try {
//         // console.log(`>>>>> viewportHeight: ${viewportHeight}, viewportWidth: ${viewportWidth}, bodyWidth: ${bodyWidth}, largeScreenWidth: ${largeScreenWidth}, widthToUse: ${widthToUse}`)
//         ctx = gsap.context(() => {
//           const scaleFactorAugmentationEffects = (props.data.zoom_effect==true) ? {scale: '2.5'} : {};
//           const scaleFactoReductionEffects = (props.data.zoom_effect==true) ? {scale: '1'} : {};
//           let startBringTextIn = -900;
//           let durationBringTextIn = 150;
//           let currentTimeline = gsap.timeline();

//           const fx_fixContainerAndHideText = currentTimeline = fixContainerAndHideText(currentTimeline, false, 'top', 150, scaleFactorAugmentationEffects);
//           const fx_fadeOutImage1 = fadeOutImage1(currentTimeline, false, -250, 250, {opacity: '0'});
//           const fx_reduceImageSize = reduceImageSize(currentTimeline, false, scaleFactoReductionEffects);
//           let fx_outTransition = null;
//           let fx_fadeTextOut = null;


//           // console.log(`************* out_transition = ${props.data.out_transition}`);

//           if (props.data.out_transition=='reduce65') {
//             startBringTextIn = -700;
//             durationBringTextIn = 250;
  
//             //  fx_outTransition = outTransitionReduce65(currentTimeline, false, -670, 300, {width: '65%'}, onLeaveHandler);
//             fx_outTransition = outTransitionReduce65StaticImage(currentTimeline, false, -670, 280, {right: '0'}, onLeaveHandler);
//           } else {
//             startBringTextIn = -700;
//             durationBringTextIn = 250;
//             fx_outTransition = outTransitionReduce0(currentTimeline, false, -670, 300, {width: '0%'});
//             fx_fadeTextOut = fadeTextOut(currentTimeline, false, -870, 300, {width: '0%'});
//           }
          
//           const fx_bringTextIn = bringTextIn(currentTimeline, false, startBringTextIn, durationBringTextIn, {left: '35%'});
//           currentTimeline.add([fx_fixContainerAndHideText, fx_fadeOutImage1, fx_reduceImageSize, fx_bringTextIn, fx_outTransition, fx_fadeTextOut])
          
//           timeline.current = currentTimeline;
  
//         }, ref);
            
//         return () => { 
//           // cleanup code (optional)
//           ctx.revert();
//         }
        
//       } catch (ex) {
//         console.log("Exception accessing GSAP context");
//         console.log(ex);
//         return () => {
  
//         }
//       }
//     }
      
//   }, []); // <- empty dependency Array so it doesn't re-run on every render!

//   let text = props.data.text;
//   let boldWords = (props.data.bold_words != undefined) ? props.data.bold_words : "";

//   let image = "";
//   if (props.data.image != undefined) {
//     try {
//       image = props.data.image[0]["getURL"] + "/@@images/image";
//     } catch (e) {
//       image = ""
//     }
//   }

//   let image2 = "";
//   if (props.data.image2 != undefined) {
//     try {
//       image2 = props.data.image2[0]["getURL"] + "/@@images/image";
//     } catch (e) {
//       image2 = ""
//     }
//   }

//   const setOrientationClass = ({ target: img }) => {
//     const { offsetHeight, offsetWidth } = img;
//     const orientationDetected = (offsetHeight > offsetWidth) ? "portrait" : "landscape";
//     setOrientation(orientationDetected);
//     setContainerClass("image-container " + orientationDetected);
    
//   }
  
//   var boldWordsArray = boldWords.split(",");
//   for (var i = 0; i < boldWordsArray.length; i++) {
//     var boldWord = boldWordsArray[i];
//     var regex = new RegExp(boldWord, 'g');
//     try {
//       text = text.replace(regex, '<span style="font-weight: bold">' + boldWord + '</span>');
//     } catch (e) {
//       text="";
//     }
    
//   }

//   if (!visible) {
//     return (<div className="image-fade-image hidden">visibility flag is set to {visible}</div>)
//   } else {

//     return (<div className="image-fade-image animations-enabled" ref={ref} >
//     <div className="image-fade-image-content-container">
//         <div className="image-container-wrapper">
//           <div className="image-container first-image">
//             <img  ref={ref1} onLoad={setOrientationClass} src={image} alt="" ></img>
//           </div> 
//           <div className="image-container second-image">
//             <img  ref={ref2} onLoad={setOrientationClass} src={image2} alt=""></img>
//           </div>  
//         </div>
//         <div className="ifi-text">
//           <div dangerouslySetInnerHTML={{ __html: text }} />
//         </div>
//         <div className='text-banner-support  hidden'/>
//       </div>  
//       <div className="image-fade-image-content-container-static static">
//         <div className="image-container-wrapper-static">
//           <div className="image-container-static single-image">
//             <img  ref={ref2} onLoad={setOrientationClass} src={image2} alt=""></img>
//           </div>  
//         </div>
//         <div className="ifi-text-static">
//           <div dangerouslySetInnerHTML={{ __html: text }} />
//         </div>
//         <div className='text-banner-support  hidden'/>
//       </div>  
//   </div>)
//   }
  
// };

// export default ImageFadeImageView;