import React, { useEffect,  useRef, useState }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import FadingImage from '../FadingImage/FadingImageView';
import { forceSameDomain } from '../utilities';

import './image-text-link.less';


 

const ImageTextLinkView = (props) => {
  let ref = useRef();
  // let [text, setText] = useState("");
  let [textContainerProps, setTextContainerProps] = useState({})

  let image = null;
  let h1Block = "";
  const componentClass = (props.data.useAsHero != undefined && props.data.useAsHero==true) ? "image-text-link-block as-hero" : "image-text-link-block";
  const titleClass= (props.data.useAsHero != undefined && props.data.useAsHero==true) ? "heavyStyle" : "lightStyle";

  if (props.data.image != undefined) {
    try {
        image = forceSameDomain(props.data.image[0]["getURL"].replace(process.env.RAZZLE_API_PATH, "") + "/@@images/image");
    } catch (e) {
        // console.log("EXCEPTION!!! ")
        console.log(e);
        // console.log (props.data.image);
        image = "";
    }  
  }


  const text = (props.data.text != undefined && props.data.text.length > 0) ? props.data.text : "";
  const cta = (props.data.cta != undefined) ? props.data.cta : "Learn more";
  const link = (props.data.target_url != undefined && props.data.target_url[0] != undefined) ? props.data.target_url[0].getURL.replace(process.env.RAZZLE_API_PATH, "") : null;
  const visible = (props.data.hidden==true) ? false : true;
  const editEnabled = (props.data.editEnabled==true) ? true : false;
  const textPosition = (props.data.text_right==true) ? 'right' : 'left';
  const useFadeImageComponent = (props.data.fade_effect==true);
  const flipClass = (props.data.flip_image==true) ? "flipped" : "original"
  const highlights = (props.data.bold_words != undefined && props.data.bold_words.length > 0) ? props.data.bold_words.split(",").map(w=>w.trim()) : [];
  const emphasisStyle = (props.data.colorize == true) ? 'colorize' : 'italicize';

  useEffect(() => {
    // let description = "";

    // try {
    //   let metas = [];
    //   try {
    //     metas = (document != undefined) ? [].slice.call(document.getElementsByTagName('meta')) : [];  
    //     const componentText = (props.data.text != undefined && props.data.text.length > 0) ? props.data.text : "";

    //     h1Block = (document != undefined) ? document.getElementsByClassName("documentFirstHeading")[0].textContent : "";
    //   } catch (e) {
    //     metas = [];
    //     h1Block = "";
    //   }
      
    //   const descriptionMeta = metas.filter((e) => e.getAttribute('name') == "description")[0];
      
    //   description = (descriptionMeta!==undefined) ? descriptionMeta.getAttribute("content") : '';
    // } catch (e) {

    // }

    const isMobile =  (typeof window !== "undefined" && 
                     window.innerWidth != null && 
                     window.innerWidth != undefined && 
                     window.innerWidth < 768) ? true : false;

    if  (props.data.useAsHero != undefined && 
         props.data.useAsHero==true && 
         isMobile) {
            // console.log(`>>>>>>>>>> HERE WE GO. Window is like this: window.viewportWidth=${window.innerWidth}; isMobile=${isMobile}; props.data.useAsHero=${props.data.useAsHero}; textContainerProps is:`);
            // console.log(textContainerProps);
    
            setTextContainerProps({
              backgroundImage: `url("${image}")` ,
            })
         } 
  }, []);

  
  const textContent = () => {
    const elements = text.split(" ").map((w) => {
      const stylableWord = w.replace(/[\.,\!\?\:]/, "");
      const segment =  (highlights.includes(stylableWord)) ? `<em class='${emphasisStyle}'>${w}</em>` : w;
      return segment;
    }).join(" ");

    return elements;
  }

  const contentClassName = `image-text-link-block-content-container ${textPosition}`;
  const imageProps = {
    data: {
        image: (props.data.image!=undefined) ? props.data.image : undefined,
        inline_classes: flipClass,
        direction: 'ltr'
    }
  }


  return( 
  <div className={componentClass} ref={ref}>
    {(visible || editEnabled) && 
        <div className={contentClassName}>
            <div className='image-container'>
                {useFadeImageComponent && <FadingImage {...imageProps}/>}
                {!useFadeImageComponent && <img src={image} className={flipClass}/>}
            </div>

            <div className='text-container' style={textContainerProps}>
              {(props.data.useAsHero && 
                <h1 role="textbox" aria-multiline="true" className={titleClass} dangerouslySetInnerHTML={{ __html: textContent() }}/>
              )}
              {(!props.data.useAsHero && 
                <h3 role="textbox" aria-multiline="true" className={titleClass} dangerouslySetInnerHTML={{ __html: textContent() }}/>
              )}
              {link!=null && 
                <div className='cta'>
                    <a href={link} >{cta} <FontAwesomeIcon icon={faArrowRight} /></a>
                </div>
              }
            </div>
        </div>  
    } 
  </div>)
};

export default ImageTextLinkView;