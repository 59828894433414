import React, { useEffect, useRef, useState }  from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { forceSameDomain } from '../utilities';


import './image-2-with-texts.less';
// import { Controller, Scene } from 'react-scrollmagic';
// import { Tween, Timeline } from "react-gsap";

const ImageWith2TextsView = (props) => {
  const ref = useRef();
  const timeline = useRef();

  const title = props.data.title;
  const preamble = props.data.preamble;
  const text = props.data.text;
  const text2 =  props.data.text2;

  // const myId = Math.floor(Math.random() * 10000);
  const myId = (title != undefined) ? title.replace(" ", '.') : 'no.title';
  const myLabel = `image-2-texts-${myId}`;

  const visible = (props.data.hidden==true) ? false : true;
  const editEnabled = (props.data.editEnabled==true) ? true : false;

  var whiteText = (props.data.white_text == false) ? "false" : "true";
  var whiteTextBackground = (props.data.white_background == false) ? "false" : "true";

  const firstHorizontalOFfset = (props.data.text_1_horizontal_offset != undefined) ? props.data.text_1_horizontal_offset : 0;
  const firstVerticalOffset = (props.data.text_1_vertical_offset != undefined) ? props.data.text_1_vertical_offset : 0;
  const secondHorizontalOFfset = (props.data.text_2_horizontal_offset != undefined) ? props.data.text_2_horizontal_offset : 0;
  const secondVerticalOffset = (props.data.text_2_vertical_offset != undefined) ? props.data.text_2_vertical_offset: 0;

  // console.log("FVO: ",firstVerticalOffset);
  // console.log("SVO: ",secondVerticalOffset);

  const text1Alignment = (props.data.text1_alignment != undefined) ? props.data.text1_alignment : "right";
  const text2Alignment = (props.data.text2_alignment != undefined) ? props.data.text2_alignment : "right";

  const blockClass = "image-2-with-texts"
  let triggerHook = 0;
  let toScale = 1;
  let fromScale = 0.5


  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.defaults({
    scrub: true,             
    toggleActions: "play pause reverse pause",
    // play, pause, resume, reverse, restart, reset, complete, none
    // 4 values set to pastStartPoint pastEndPoint reEnter scrollBackPastTheStart

  })

  useEffect(() => {

    try {
      const body = document.getElementsByTagName("body")[0];
      const bodyWidth = body.offsetWidth;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const viewportExcess = viewportWidth - bodyWidth;
      const viewportExcessPercent = (viewportExcess/bodyWidth) * 100;
      const largeScreenWidth = (0.65*viewportWidth/bodyWidth);
      const widthToUse = 0.65*largeScreenWidth;
      const leftMargin = (viewportWidth-bodyWidth)/2;
      let textYPositionOnEnter = 0, textHeight = 0;
      let positionOnEnter = 0;
      let componentHeight = 0;
      let initialScrollPosition = 0;

      const fixContainer = (markers=false, start=230, duration=550, cssOptions={}) => {
        // return gsapTimeline 
        var tl = gsap.timeline();
        return tl 
                  .addLabel("fixContainer")
                  .to(".image-with-2-texts-scroller", {
                    ...cssOptions,
                    scrollTrigger: {
                      markers: markers,
                      id: `dummyEffect-${myLabel}`,
                      trigger: '.image-with-2-texts-scroller span',
                      start: `top ${start}`,
                      end: (self) => `+=${duration}`,
                      onEnter: (self) => {
                        const containerBlock = self.trigger.parentNode.parentNode.parentNode;
                        const previousSibling = containerBlock.previousSibling;
                        const text = containerBlock.querySelector(".text-container");
                        const allOfThisType = body.querySelectorAll(".image-2-with-texts");

                        // let position = 0;

                        // allOfThisType.forEach((elem, key) => {
                        //   if (containerBlock==elem) {
                        //     position = key;
                        //   }                          
                        // });

                        // if (position!=allOfThisType.length-1) {
                        //   allOfThisType[position+1].style.marginTop = `600vh`;                          
                        // }


                        
                        componentHeight = containerBlock.clientHeight;
                        initialScrollPosition = window.pageYOffset + containerBlock.getBoundingClientRect().top;
      
                        // console.log(`>>> .image-2-with-texts fixContainer ON ENTER  ${myLabel} .preamble-container, state -> viewportHeight=${viewportHeight}; componentHeight=${componentHeight}; initialScrollPosition=${initialScrollPosition}`)
                        // console.log(containerBlock);

                        containerBlock.classList.add("fixed");
                        containerBlock.classList.remove("initial");
                        containerBlock.style.left = `${leftMargin}px`;
                        containerBlock.style.width = `${bodyWidth}px`;
                        previousSibling.style.marginBottom = `300vh`;
                      },
      
                      onLeave: (self) => {
                        // console.log(`>>> fixContainer ON LEAVE ${myLabel}`)
                        // containerBlock.scrollTo(0,300);
                      },
                    }    
                  })
  
  
                .addLabel("fixContainerEnd")
      }

      const looseContainer = (markers=false, start=230, duration=550, cssOptions={}, onLeaveHandler) => {
        // return gsapTimeline 
        var tl = gsap.timeline();
        return tl 
                  .addLabel("looseContainer")
                  .to(".image-with-2-texts-scroller", {
                    ...cssOptions,
                    scrollTrigger: {
                      markers: markers,
                      id: `dummyEffect-${myLabel}`,
                      trigger: '.image-with-2-texts-scroller span',
                      start: `top ${start}`,
                      end: (self) => `+=${duration}`,
                      // onEnter: (self) => {
                      //   console.log(`>>> .image-2-with-texts looseContainer ON ENTER  ${myLabel} `);
                      // },
      
                      onLeave: (self) => {
                        const containerBlock = self.trigger.parentNode.parentNode;
                        const outerBlock = containerBlock.parentNode;
                        const previousSibling = outerBlock.previousSibling;
                        const textMarginBottom = viewportHeight-textYPositionOnEnter-textHeight;
  
                        // console.log(`ON LEAVE  looseContainer -> positionOnEnter=${positionOnEnter} `);
  
  
                        // avoid looping due to window.scrollTo instruction
                        if (!outerBlock.classList.contains("reduce65")) {
                          // Let's start by removing the timeline animations, 
                          // as we do NOT want to run them again. 
                          onLeaveHandler();
  
                          
                          // text.style.marginBottom = `${textMarginBottom}px`;
                          // outerBlock.classList.remove("animations-enabled");
                          outerBlock.classList.remove("fixed");
                          outerBlock.classList.add("reduce65");
                          // outerBlock.style.left = `0px`;

                          // containerBlock.classList.add("animations-disabled");
                          previousSibling.style.marginBottom = `0`;
                          window.scrollTo(0, initialScrollPosition);
                          ScrollTrigger.refresh();
                        }                      },
                    }    
                  })
  
  
                .addLabel("looseContainerEnd")
      }

      const showPreamble = (markers=false, start=230, duration=550, cssOptions={}) => {
        // return gsapTimeline 
        var tl = gsap.timeline();
        return tl 
                  .addLabel("showPreamble")
                  .to(".image-2-with-texts .preamble-container", {
                    ...cssOptions,
                    scrollTrigger: {
                      markers: markers,
                      id: `showPreamble-${myLabel}`,
                      // trigger: ".image-2-with-texts-content-container h2",
                      // trigger: ".preamble-container h2",
                      trigger: '.image-with-2-texts-scroller span',
                      // start: (self) => {
                      //   // const containerBlock = self.trigger.parentNode.parentNode.parentNode.parentNode;
                      //   // const containerBlock = self.trigger;
                      //   const containerBlock = self.trigger.parentNode.parentNode.parentNode;
                      //   const allOfThisType = body.querySelectorAll(".image-2-with-texts");
                      //   let position = 0;

                      //   allOfThisType.forEach((elem, key) => {
                      //     if (containerBlock==elem) {
                      //       position = key;
                      //     }
                      //     console.log(`Found an element with key=${key}; Is *this? ${containerBlock==elem}`);
                      //     console.log(elem);
                          
                      //   });

                      //   console.log(`trigger pos is ${start + 0.5*position*100}%`);
                      //   // return `top ${start - 0.5*position*100}%`
                      //   return "top top";
                      // }, 
                      start: `top top`,
                      end: (self) => `+=${duration}`,
                      // onEnter: (self) => {
                      //   // const containerBlock = self.trigger.parentNode.parentNode.parentNode.parentNode;
                      //   // const containerBlock = self.trigger;
                      //   const containerBlock = self.trigger.parentNode.parentNode.parentNode;
                      //   const previousSibling = containerBlock.previousSibling;
                      //   const text = containerBlock.querySelector(".text-container");
                      //   const allOfThisType = body.querySelectorAll(".image-2-with-texts");
                        


                      //   componentHeight = containerBlock.clientHeight;
                      //   initialScrollPosition = window.pageYOffset + containerBlock.getBoundingClientRect().top;
      
                      //   console.log(`>>> .image-2-with-texts showPreamble ON ENTER  ${myLabel} .preamble-container, state -> viewportHeight=${viewportHeight}; componentHeight=${componentHeight}; initialScrollPosition=${initialScrollPosition}`)
                      //   console.log(containerBlock);

                      //   containerBlock.classList.add("fixed");
                      //   containerBlock.classList.remove("initial");
                      //   containerBlock.style.left = `${leftMargin}px`;
                      //   containerBlock.style.width = `${bodyWidth}px`;

                      //   // Because containerBlock becomes fixed, it decouples from the page scroll flow.
                      //   // For that reason I'm adding a 100vh margin bottom to the previous 
                      //   // previousSibling.style.marginBottom = '100vh';

                      //   // *** ADD 3 ***
                      //   // text.classList.add("hidden");
                      //   // text.style.width = `${0.35*bodyWidth}px`;
                      //   // text.style.left = `${leftMargin + 0.65*bodyWidth}px`;

                      // },
      
                      // onLeave: (self) => {
                      //   // const containerBlock = self.trigger.parentNode.parentNode.parentNode.parentNode;
                      //   // const containerBlock = self.trigger;
                      //   const containerBlock = self.trigger.parentNode.parentNode.parentNode;
                      //   const newPosition = initialScrollPosition + componentHeight + viewportHeight; //+componentHeight-100;
                      //   const newScrollPosition = window.pageYOffset + containerBlock.getBoundingClientRect().top;
                      //   // preambleContainer.classList.add("hidden");
                      //   // containerBlock.classList.remove("fixed");
                      //   // window.scrollTo({
                      //   //   top: newPosition, // viewportHeight
                      //   //   behaviour: 'smooth'
                      //   // });
                      //   console.log(`>>> .image-2-with-texts showPreamble ON LEAVE ${myLabel}, state -> viewportHeight=${viewportHeight}; newScrollToPosition=${newPosition}; newActualScrollPosition=${newScrollPosition}`)
                      //   // containerBlock.scrollTo(0,300);
                      // },

                      onUpdate: (self) => {
                        const containerBlock = self.trigger.parentNode.parentNode.parentNode.parentNode;
                        const preamble = containerBlock.querySelector(".preamble-container");
                        preamble.style.opacity = self.progress;
                      }
                    }    
                  })
  
  
                .addLabel("showPreambleEnd")
      }

      const fixImageFullScreen = (markers=false, start=100, duration=350, cssOptions={}) => {
        // return gsapTimeline 
        var tl = gsap.timeline();
        return tl 
                  .addLabel("fixImageFullScreen")
                  .to(".image-2-with-texts .image-container img", {
                    ...cssOptions,
                    // ease: Power1.easeIn,
                    scrollTrigger: {
                      id: `fixImageFullScreen-${myLabel}`,
                      markers: markers,
                      trigger: ".image-2-with-texts .image-container img",
                      start: (self) => `top ${start}`, 
                      end: (self) => `+=${duration}`,
                      // onEnter: (self) => {
                      //   const containerBlock = self.trigger.parentNode.parentNode.parentNode;
                      //   const text = containerBlock.querySelector(".text-container");
                      //   positionOnEnter = window.pageYOffset ;

                      //   console.log(`>>>>> ENTER fixImageFullScreen containerBlock`);
                      //   // console.log(containerBlock);

                      //   // console.log(`>>>>> textBlock`);
                      //   // console.log(text);

                      //   containerBlock.classList.add("fixed");
                      // },
                    }    
                  })
  
  
                .addLabel("fixImageFullScreenEnd")
      }

      const bringTextIn = (markers=false, start=0, duration=100, selector='.first', cssOptions={}) => {
        // return gsapTimeline 
        var tl = gsap.timeline();
        return tl 
                  .addLabel("bringTextInStart")
                  .from(`.image-2-with-texts .text-container ${selector}` , {
                    ...cssOptions,
                    
                    scrollTrigger: {
                      markers: markers,
                      trigger: ".image-with-2-texts-scroller span",
                      start: (self) => `top ${start}`, 
                      end: (self) => `+=${duration}`,
                      onEnter: (self) => {
                        const containerBlock = self.trigger.parentNode.parentNode.parentNode;
                        const text = containerBlock.querySelector(".text-container");

                        text.style.width = `${0.35*bodyWidth}px`;
                        // const text = containerBlock.querySelector(selector);

                        // console.log(`ON ENTER bringTextIn -> start=${start}`);
                        // console.log(containerBlock);
                        
                        containerBlock.classList.add("text-fly-in");
                        // text.classList.remove("hidden");
                        
                      },
                      onLeave: (self) => {
                        // console.log(`ON LEAVE bringTextIn`);
                      }
                    }    
                  })
  
  
                .addLabel("bringTextInEnd")
      }

      const outTransitionReduce65 = (markers=false, start=-900, duration=300, cssOptions={}, onLeaveHandler) => {
        // return gsapTimeline 
        var tl = gsap.timeline({
          // onComplete: () => {
          //   alert("Hello");
          // }
        });
        return tl 
                  .addLabel("outTransitionReduce65Start")
                  .from(".text-banner-support", {
                    ...cssOptions,
                    
                    scrollTrigger: {
                      markers: markers,
                      // trigger: ".image-2-with-texts .image-container img",
                      trigger: ".image-with-2-texts-scroller span",
                      start: (self) => `top ${start}`, 
                      end: (self) => `+=${duration}`,
                      
                      onEnter: (self) => {
                        const containerBlock = self.trigger.parentNode.parentNode;
                        const outerBlock = containerBlock.parentNode;
                        const text = containerBlock.querySelector(".text-container");
                        const textBannerSupportWidth = 0.35*bodyWidth;
                        const textBannerSupportContainer = containerBlock.querySelector(".text-banner-support");
  
                        textBannerSupportContainer.style.width = `${textBannerSupportWidth}px`;
                        textYPositionOnEnter = text.offsetTop;
                        textHeight = text.clientHeight;
                        // text.classList.add("hidden");

                        // alert("Hello");
                        // console.log(`ON ENTER  outTransitionReduce65 -> textBannerSupportWidth: ${textBannerSupportWidth}; textYPositionOnEnter=${textYPositionOnEnter}; viewportHeight=${viewportHeight};`);
                      },
    
                      // onLeave: (self) => {
                      //   // alert("Goodbye");
                      //   const containerBlock = self.trigger.parentNode.parentNode;
                      //   const outerBlock = containerBlock.parentNode;
                      //   const previousSibling = outerBlock.previousSibling;
                      //   const outerBlockPosition = window.pageYOffset + outerBlock.getBoundingClientRect().top;
                      //   const text = outerBlock.querySelector(".text-container-static");
  
                      //   const yPosition = positionOnEnter;
                      //   const textMarginBottom = viewportHeight-textYPositionOnEnter-textHeight;
  
                      //   console.log(`ON LEAVE  outTransitionReduce65 -> positionOnEnter=${positionOnEnter}; viewportHeight=${viewportHeight}; textMarginBottom=${textMarginBottom} `);
  
  
                      //   // avoid looping due to window.scrollTo instruction
                      //   if (!outerBlock.classList.contains("reduce65")) {
                      //     // Let's start by removing the timeline animations, 
                      //     // as we do NOT want to run them again. 
                      //     onLeaveHandler();
  
                          
                      //     // text.style.marginBottom = `${textMarginBottom}px`;
                      //     // outerBlock.classList.remove("animations-enabled");
                      //     outerBlock.classList.remove("fixed");
                      //     outerBlock.classList.add("reduce65");
                      //     // outerBlock.style.left = `0px`;

                      //     // containerBlock.classList.add("animations-disabled");
                      //     previousSibling.style.marginBottom = `0`;
                      //     window.scrollTo(0, initialScrollPosition);
                      //     ScrollTrigger.refresh();
                      //   }
                      // },
  
                      onLeaveBack: (self) => {
                        // const containerBlock = self.trigger.parentNode.parentNode;
                        // const outerBlock = containerBlock.parentNode;
                        // const currentScroll = window.pageYOffset;
                        // const outerBlockPosition = window.pageYOffset + outerBlock.getBoundingClientRect().top;
                        // const yPosition = outerBlockPosition + viewportHeight;
  
                        // outerBlock.classList.remove("animations-enabled");
                        // outerBlock.classList.remove("fixed");
  
                        
                        // console.log(`ON EnterBack outTransitionReduce65. outerBlock is`);
                        // console.log(outerBlock);
                        // console.log(`currentScroll=${currentScroll}; outerBlockPosition=${outerBlockPosition}; yPosition=${yPosition}; outerBlock.scrollTop=${outerBlock.scrollTop}; viewportHeight=${viewportHeight}`)
  
                      }
                      
                    }    
                  })
  
                .addLabel("outTransitionReduce65End")
      }
      
      // console.log(`>>>>> viewportHeight: ${viewportHeight}, viewportWidth: ${viewportWidth}, bodyWidth: ${bodyWidth}, largeScreenWidth: ${largeScreenWidth}, widthToUse: ${widthToUse}`)

      if (visible && !editEnabled) {
        let ctx;

        const onLeaveHandler = () => {
          // console.log(`>>>>>>>>>>> JUST GOT TO onLeaveHandler }`)
          // timeline.current.remove([fx_fixContainerAndHideText, fx_fadeOutImage1, fx_reduceImageSize, fx_bringTextIn, fx_outTransition, fx_fadeTextOut])
          // timeline.current && timeline.current.progress(0).kill() && timeline.current.clear();
          // timeline.current.clear();
          // console.log(timeline.current.getChildren());
          ctx.revert();
        }

        ctx = gsap.context(() => {

          let currentTimeline = gsap.timeline();

          const fx_fixContainer = fixContainer(false, "top", 450, {opacity: '1'});
          const fx_showPreamble = currentTimeline = showPreamble(false, "top", 450, {opacity: '1'});
          const fx_fixImageFullScreen = fixImageFullScreen(false, "-415px", 350, {opacity: '1'});
          const fx_bringFirstTextIn = bringTextIn(false, "-900px", 430, ".first", {left: '150%'});
          const fx_bringSecondTextIn = bringTextIn(false, "-1350px", 250, ".second", {left: '150%'});
          const fx_outTransition = outTransitionReduce65(true, "-800px", 480, {right: '-40vw'}, onLeaveHandler);
          const fx_looseContainer = looseContainer(false, "-1600px", 5, {opacity: '1'}, onLeaveHandler);


          // const fx_dummyEffect = currentTimeline = dummyEffect(false, "top", 450, {opacity: '1'});

          // currentTimeline.addLabel("start");
          // currentTimeline.add([fx_showPreamble, fx_fixImageFullScreen, fx_bringFirstTextIn, fx_bringSecondTextIn, fx_outTransition]);
          currentTimeline.add([fx_fixContainer, fx_showPreamble, fx_fixImageFullScreen, fx_bringFirstTextIn, fx_outTransition, fx_looseContainer]);

          

          timeline.current = currentTimeline;
  
              // .to(".image-2-with-texts .preamble-container", {
              //   top: '-500px',
              //   scrollTrigger: {
              //     markers: true,
              //     trigger: ".image-2-with-texts h2",
              //     start: (self) => 'top 10%', 
              //     end: (self) => `+=550`,
              //     onEnter: (self) => {
              //       const containerBlock = self.trigger.parentNode.parentNode.parentNode.parentNode;
              //       componentHeight = containerBlock.clientHeight;
              //       initialScrollPosition = window.pageYOffset + containerBlock.getBoundingClientRect().top;
  
              //       console.log(`>>> ON ENTER ${myLabel}, state -> viewportHeight=${viewportHeight}; componentHeight=${componentHeight}; initialScrollPosition=${initialScrollPosition}`)
              //       containerBlock.classList.remove("fixed");
              //       containerBlock.classList.add("inflow");
              //       // containerBlock.style.marginTop = "420px";
                    
              //       // containerBlock.style.marginTop = "420px";
              //     },
  
              //     onLeave: (self) => {
              //       const containerBlock = self.trigger.parentNode.parentNode.parentNode.parentNode;
              //       const newPosition = initialScrollPosition + componentHeight + viewportHeight; //+componentHeight-100;
              //       const newScrollPosition = window.pageYOffset + containerBlock.getBoundingClientRect().top;
              //       // preambleContainer.classList.add("hidden");
              //       containerBlock.classList.remove("fixed");
              //       window.scrollTo({
              //         top: newPosition, // viewportHeight
              //         behaviour: 'smooth'
              //       });
              //       console.log(`>>> ON LEAVE ${myLabel}, state -> viewportHeight=${viewportHeight}; newScrollToPosition=${newPosition}; newActualScrollPosition=${newScrollPosition}`)
              //       // containerBlock.scrollTo(0,300);
              //     },
              //   }    
              // })
  
  
  
              // .to(".image-2-with-texts .image-container", {
              //   opacity: '1',
              //   // ease: Power1.easeIn,
              //   scrollTrigger: {
              //     markers: true,
              //     trigger: ".image-2-with-texts .image-container img",
              //     start: (self) => 'top 100%', 
              //     end: (self) => `+=350`,
              //     onEnter: (self) => {
              //       // const containerBlock = self.trigger.parentNode.parentNode.parentNode.parentNode;
              //       // containerBlock.classList.add("fixed");
              //       console.log(`>>>> ******* >>>>>>> ******  enter image resize`); 
              //     },
              //     onUpdate: (self) => {
              //       console.log(`>>>> on update progress=${self.progress}`);
              //     }
  
              //   }    
              // })

  
        }, ref);

        return () => { 
          // cleanup code (optional)
          ctx.revert();
        }
      } 
          
    } catch (ex) {
      console.log("Exception accessing GSAP context");
      console.log(ex);
      return () => {

      }
    }

  // -- ANIMATION CODE HERE --

}, []); // <- empty dependency Array so it doesn't re-run on every render!
  
  var image = "";
  if (props.data.image != undefined) {
    try {
      image = forceSameDomain(props.data.image[0]["getURL"] + "/@@images/image");
    } catch (e) {
      image = ""
    }
  }


  if (typeof window !== "undefined") {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

    if (vw < 768) {
      triggerHook = 0.25;
      fromScale = 2;
      toScale = 4;
    }
    
  }

  return (<div className='image-2-with-texts initial' ref={ref} id={myLabel}>
    <div className='image-2-with-texts-content-container'>
      {/* <h1>test</h1> */}
      <div className='image-with-2-texts-scroller'><span>&nbsp;</span></div>

      <div className='preamble-container'>
        <div className='preamble-title'>
          <h2><strong>{title}</strong></h2>
        </div>
        <div className='preamble-lead'>
          <p className="lead">{preamble}</p>
        </div>
      </div>
      <div className='image-container'>
        <img src={image} alt=""></img>
      </div>
      <div className='text-banner-support  hidden'/>
      <div className='text-container'>
        <div className="text first" whitetext={whiteText}  whitebackground={whiteTextBackground} alignment={text1Alignment}>
            {text}
        </div>
        <div className="text second" whitetext={whiteText}  whitebackground={whiteTextBackground} alignment={text1Alignment}>
            {text2}
        </div>
      </div>
    </div>
    {/* <div className="image-2-with-texts-content-container-static static">
        <div className="image-container-wrapper-static">
          <div className="image-container-static single-image">
            <img src={image} alt=""></img>
          </div>  
        </div>
        <div className="text-container-static">
          <div className="text first" whitetext={whiteText}  whitebackground={whiteTextBackground} alignment={text1Alignment}>
            {text}
          </div>  
          <div className="text second" whitetext={whiteText}  whitebackground={whiteTextBackground} alignment={text2Alignment}>
            {text2}
          </div>  
        </div>
        <div className='text-banner-support  hidden'/>
    </div>   */}
  </div>);



  // return( <div>
  //       <Controller>
  //       <Scene
  //           duration={800}
  //           pin={{ pushFollowers: true }}
  //           triggerHook={triggerHook}
  //           offset={0}
  //         >
  //         {(progress) => (
  //       <div className={blockClass}>
  //         <Timeline totalProgress={progress} paused>
  //           <Timeline target={
  //             <div className="image-2-texts-container-wrapper">
  //               {/* <Tween from={{margin: "200px 0 0 0"}} to={{margin: "0 0 0 0"}} />   */}
  //               <div className="image-container">
  //                 <Timeline target={
  //                     <img src={image} alt=""></img>
  //                   }>
  //                     {/* <Tween from={{scale: 1}} to={{scale: 2, duration:2}} /> */}
  //                     <Tween from={{scale:fromScale}} to={{scale:toScale, duration:0.5}} />
  //                     {/* <Tween from={{scale:fromScale}} to={{scale:toScale, duration:2, x:0, y:0, clipPath: 'inset(0% -50vh 0%)'}} /> */}
  //                 </Timeline>
              
  //               </div>

  //               <Timeline
  //                     target={
  //                       <div className="text" whitetext={whiteText}  whitebackground={whiteTextBackground} alignment={text1Alignment}>
  //                         {text}
  //                       </div>
  //                     }
  //                   >
  //                     <Tween from={{x:firstHorizontalOFfset, y:5000 }} to={{x:firstHorizontalOFfset, y: firstVerticalOffset , duration:1.7}} />
                      
  //               </Timeline>

  //               <Timeline 
  //                     target={
  //                       <div className="text2" whitetext={whiteText} whitebackground={whiteTextBackground} alignment={text2Alignment}>
  //                         {text2}
  //                       </div>
  //                     }
  //                   >
  //                     <Tween from={{x:secondHorizontalOFfset, y:5000 }} to={{x: secondHorizontalOFfset, y: secondVerticalOffset , duration:1.7}} />

  //               </Timeline>
  //             </div>
  //           }/>
  //         </Timeline>
  //         </div>
  //         )}
  //         </Scene>
  //         {/* <Scene
  //           duration={400}
  //           pin={{ pushFollowers: true }}
  //           triggerHook={0.4}
  //           offset={0}>
            

  //         </Scene> */}
  //         </Controller> 

  //        </div>)
};

export default ImageWith2TextsView;